@import "../css/utility.scss";
.css-1rq8huz-MuiGrid-root,
.css-3weykx-MuiGrid-root,
.MuiGrid-item {
  font-size: 14px !important;
  padding: 0px;
}
.tableHeader {
  background-color: var(--bg-white); 
  border: 1px solid var(--borderLightGrey);
  border-radius: var(--border-radius4);
  padding: 8px;
}
.table-data {
  border: 1px solid var(--bordertableData) !important;
  border-radius: var(--border-radius4);
  margin: 5px 0 !important;
  background-color: var(--bg-white);
  padding: 2px;
  align-items: center;
  &.AuditActivity {
    .MuiGrid-item {
      padding: 5px 7px;
      font-weight: var(--font-normal);
      text-align: left;
      &:last-child {
        text-align: center;
      }
    }
  }
}
// .css-mhc70k-MuiGrid-root > .MuiGrid-item {
//   padding-top: 0 !important;
// }
.action-wrap-outer {
  background: var(--bg-administrator-table);
  height: calc(100vh - 10p5x);
  margin-right: 18px !important;
  .action-wrap {
    align-items: center;
    padding: 4px 20px;
    .action-select-wrap {
      margin-right: 5px;
    }
    .audit-daterange-wrap {
      padding: 1;
      margin: 3px;
      @include dashboard-wrap;
    }
    .react-datepicker-popper {
      z-index: 22;
    }
  }
}
.search {
  position: "relative";
  border-radius: 1px;
  background-color:var(--bg-white);
  border: 1px solid #c5c0c0;
  border-radius: var(--border-radius6);
  display: flex;
  align-items: center;
  margin: 0 15px;
  width: "100%";
  padding: 4px;
}
.search-wrapper {
  padding: 0 2px;
  height: "100%";
  position: "absolute";
  pointer-events: "none";
  display: "flex";
  align-items: "center";
  justify-content: "center";
}
.hidden-row {
  padding: 5px;
  background:var(--bg-DayCard);
  align-items: center;
  width: 100% !important;
  border-bottom: 1px solid var(--borderLightGrey);
  margin: 0 !important;
  &:first-child {
    margin-top: -5px !important;
  }
  &:last-child {
    border-bottom: none;
  }
  .auditTableBatchN {
    text-align: "right";
    padding-right: 20px;
  }
}
.text-center {
  text-align: center;
}
.input-drop-select {
  background-color: var(--bg-DayCard);
  border: 0;
  border-radius: var(--border-radius6);
  padding: 0;
  color: var(--theme-color);
  font-size: 14px !important;
  border-right: transparent;
  // border-top-right-radius: 0;
  // border-bottom-right-radius: 0;
  margin: 3.2px;
}
.break-word {
  word-break: break-all;
  font-weight:var(--font-normal);
} 
.Audit-table-wrap {
  padding-left: 3;
  @include dashboard-wrap;
  height: 63vh;
  overflow: scroll;
  padding: 0 0 0 24px;
  overflow-x: hidden;
  position: relative;
  .tableHeader {
    font-size: 14px;
    font-weight: var(--font-bold);
    color: #202020;
    padding: 14px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 21;
    .MuiGrid-root:last-child{
      text-align: center!important;
    }
  }
}
// @media screen and (min-width: 1600px) {
//   .search-container,
//   .search {
//     border-radius: 10px;
//   }
// }
