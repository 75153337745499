@import "../../../css/utility.scss";

.Sidebar {
  position: absolute;
  width: 330px;
  height: 710px;
  left: 0px;
  top: 70px;
  background-color: var(--theme-color);
}

.added-list {
  background-color: var(--bg-Table-Disable);
  margin: 5px;
  border-radius: var(--border-radius6);
}

.scroller {
  overflow-y: scroll;
  height: 70vh !important;
  margin-top: 2vh !important;
  margin-bottom: 0 !important;
  // scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;

  .selected-file-margin {
    padding-left: 15px;
  }
}

.nested-folder {
  margin-left: 47px;
}

.notselected {
  display: flex;
  cursor: pointer;
}

.selected {
  display: flex;
  cursor: pointer;
  background-color: var(--bg-DayCard);
  border-radius: var(--border-radius6);
  padding: 5px 10px;
  min-width: 200px;
}

.card-div {
  padding: 0.13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-div-unselected {
  padding: 0.13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--theme-color);
}

.header-select {
  .MuiOutlinedInput-input {
    padding: 7.5px 0px;
  }

  .ProductTypeList-wrap {
    min-width: 200px;

    .ProductTypeList {
      margin-top: -9px;
    }
  }
}

.add-btn {
  @include button-bg-blue;
  margin: 0 15px !important;
  height: 38px;
  text-transform: none !important;
}

.btn-continue {
  margin: 0 !important;
  margin-left: auto !important;
  @include button-bg-blue;
}

.list_directory-container {
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 99%;
    height: fit-content;
  }

  li {
    width: 30%;
  }
}

.list-directory {
  position: relative;

  .header-select {
    height: calc(100vh - 64px);
  }

  .Add-batch-records {
    display: flex;
    padding: 8px 30px;
    border-bottom: 1px solid var(--borderLightGrey);
  }

  .batch-product-type {
    width: 200px;
  }
}

.sub-folder {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 76px;
  min-width: 199px;
  max-width: 199px;
  background-color: var(--bg-Table-Disable);
  border-bottom: 1px solid var(--borderLightGrey);
  padding: 0 10px;
  font-size: 12px;
  line-height: 14px;

  .nestest-subFolder {
    display: flex;
    align-items: center;
  }

  .formlabel {
    margin: 0 !important;
  }

  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:first-child {
    margin-top: -5px;
  }

  .folderNTiltle-wrap {
    display: flex;
    justify-content: center;
    cursor: pointer;

    p {
      padding: 0 8px;
    }
  }
}

.folder-icon {
  color: #ffb000;
}

.m-0 {
  margin: 0 !important;
}

.uploadBatchHeader-wrap {
  background-color: var(--borderLightGrey);
}
.scroll-wrapper {
  height: "485px !important";
}
.radio-wrap {
  margin-right: 10px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ml-auto  {
  margin-left: auto  !important;
}

.dir-container {
  overflow-y: scroll;
  height: calc(100vh - 200px);
  box-shadow: none;
  width: 100%;
  margin: 0 auto;
  padding: 10px 20px 10px 20px;
}


.lang-select {
  .MuiOutlinedInput-root {
    border: 0px !important;
  }
}
