@import "../../../css/utility.scss";

.css-1ynyhby {
  margin: 0;
}
.checkboxicon{
  margin: -4px 4px;
}

.parent-table,
.parent-table td,
.parent-table th {
  border-collapse: separate !important;
  border-spacing: 8px !important;
  border: 0px;
font-weight:var(--font-normal);
}

.parent-table tr {
  outline: 1px solid var(--bordertableData) !important;
  border-radius: var(--border-radius4);
}

.parent-table {
  width: 99% !important;
  .parent-table-th {
    .parent-table-row{
      &:last-child td, &:last-child th{
        border: 0;
      }
    }
    tr {
      outline: 1px solid #b9b9b9 !important;
      th{
      font-weight: var(--font-bold) !important;
      padding: 12px !important;
      }
    }
  }
  
  .MuiTableCell-root{
    padding:8px !important;
  }
}

.marginRight {
  margin-right: 18px !important;
}

.manageUser-container{
  overflow-y: scroll;
  height: calc(100vh - 171px);
  box-shadow: none;
}
.border-0{
  border: 0!important;
}
.pagination-wrap pagination-bg{
  background-color: #fff;
}
.MuiPopover-paper{
  border-radius:var(--border-radius6);
  border: 1px solid #b9b9b9!important;
  box-shadow: none!important;
}