@import "../../../css/utility.scss";

td {
  height: 32px !important;
  padding: 7px 5px !important;
  border-bottom: 0 !important;
}

.table-style {
  overflow: scroll;

  th {
    background-color:var(--bg-Table-Disable);
    min-width: 250px;
  }

  td,
  th {
    font-size: 14px;
    font-weight: var(--font-bold);
    text-align: center;
  }
}
 
.table-disable {
  background-color:var(--bg-Table-Disable);
  color: #b9b9b9;
}

.MuiTableRow-root {
  outline: 1px solid var(--bordertableData)!important;
}

.addRow-dataMap {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  padding: 5px 5px !important;
  // margin-bottom: 3px!important;
  // background: var(--bg-DayCard);
}

.addRow-dataMap-reset {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  padding: 5px 5px !important;
  justify-content: flex-end;
  // background: var(--bg-DayCard);
}

.reset-data {
  border-radius:var(--border-radius4);
  height: 36px;
  @include button-outline-black;
  margin: 5px 15px 0 10px !important;
}

.DataMapTable {
  display: flex !important;
.cell-outer{
  height: 100%!important;
}
  .MuiGrid-item {
    .label {
      background: white;
    }
  }

  .range-selector {
    align-items: center !important;
    width: 85px !important;
    min-width: 85px !important;
    justify-content: center;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &:first-child {
    position: sticky !important;
    top: 0 !important;
    z-index: 44;

    .MuiPaper-root {
      border-top: 1px solid #7c7c7c;
      justify-content: center;
      align-items: center;
      background: var(--bg-Table-Disable) !important;
      line-height: 14px !important;
      // &:nth-child(2) {
      //   width: 85px;
      //   min-width: 85px;
      // }
      // &:nth-child(3) {
      //   width: 85px;
      //   min-width: 85px;
      // }
      .label {
        font-weight: var(--font-bolder);
        color: rgba(0, 0, 0, 0.6);
      }
    }
 
    .label {
      // padding: 0 8px;
      max-width: 90px;
    }
  }

  .MuiPaper-root, .MuiInputBase-root-MuiInput-root {
    border-bottom: 1px solid #7c7c7c;
    border-right: 1px solid #7c7c7c;
    border-radius: 0;
    display: flex;
    font-size: 14px !important;
    padding: 0 !important;
    position: relative;
    min-width: 125px !important;
    width: 100px;
    word-break: break-all;
    align-items: center;
    height: 100% !important;;

    &.DataMap-header {
      padding: 6px 4px !important;
    }

    .MuiIconButton-root {
      padding: 0 !important;
      position: absolute;
      right: 0;
      top: 2px;
    }

    &:first-child {
      // border-top: 1px solid #7c7c7c;
      border-left: 1px solid #7c7c7c;
      // width: 100% !important;
      position: sticky;
      z-index: 1;
      left: 0;
    }
  }

  .MuiSelect-root,
  .MuiOutlinedInput-root,
  .MuiMenuItem-root {
    font-size: 14px !important;
    height: 100%;
    padding: 0 0 !important;

    input {
      height: 92%;
      padding: 0 6px !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }

  .datatype-wrap,
  .datatype-wrap-selector {
    align-items: center;

    .MuiSelect-icon {
      right: -2px !important;
      top: calc(50% - 0.4em) !important;
    }

    select {
      height: 100%;
      border: 0;
    }
  }

  .rangeSelect-wrap {
    display: flex;
    width: 100px;
    align-items: center;
    justify-content: center;

    .MuiInput-input {
      min-width: 60px;
      padding: 0 !important;
      font-size: 12px;
      line-height: 16px;
    }

    .MuiTextField-root {
      height: 100%;
      justify-content: center;
      font-size: 12px;
    }
  }
}

.DataMapTable-wrap {
  overflow: scroll;
  height: 100%;
  // padding: 5px 5px;
  flex-wrap: nowrap !important;
  flex-direction: column !important;

  &.prePocessor {
    height: calc(100vh - 204px) !important;
    position: relative;
  }

  &.validationValidator {
    height: calc(100vh - 154) !important;
    position: relative;
  }

  .readMore-btn {
    padding: 0;
    position: absolute;
    right: -3px;
    height: 20px;
    cursor: pointer;
    top: 0;
    border: 0;
    background-color: transparent;

    .MuiSvgIcon-root {
      width: 18px !important;
      height: 18px !important;
    }
  }

  select:disabled {
    border-width: 0 !important;
    border-bottom: 0 !important;
  }

  .MuiOutlinedInput-input {
    padding: 8px 5px !important;
    line-height: 16px;
    font-size: 12px;
  }

  .MuiTableCell-root {
    // min-width: 114px !important;
    border-bottom: 0 !important;
    padding: 9px 5px !important;
    padding: 12px 5px !important;
  }

  .MuiPaper-root {
    box-shadow: none !important;
    align-items: flex-start;

    // &:nth-child(2){
    //   margin-left: 126px;
    // }
    .textfieldClass {
      width: 35px;
      padding-left: 4px;
      border: 0;
      background-color: transparent;
    }

    textarea {
      border: 0 !important;
      resize: none;
      overflow: hidden;
      height: 100% !important;
      padding-right: 12px;
      // line-height: 14px !important;
      font-weight: var(--font-normal);
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8)!important;
      padding:5px 5px 0px !important
      // height: 100%;
      // &:focus-visible {
      //   outline: 0px !important;
      //   border-bottom: 2px solid #1976d2 !important;
      // }
    }
  }
}

.container-2 {
  .DataMapTable-wrap {
    &.prePocessor {
      height: calc(100vh - 62px) !important;
      position: relative;
    }
  }
}

.datatype-wrap:first-child,
.datatype-wrap-selector:first-child {
  margin-left: 100px;

  .MuiOutlinedInput-input {
    // padding: 8px 5px !important;
    // line-height: 16px;
    cursor: pointer;
  }
}

.datatype-wrap,
.datatype-wrap-selector {
  .disableCell {
    cursor: pointer;
    // opacity: 1.9;
    color:var(--color-black) !important;
  }

  .disable_opacity {
    cursor: pointer;
  }
}

.datatype-wrap-selector {
  width: 85px !important;
  min-width: 85px !important;
}

.DataMap-header {
  font-weight: var(--font-bold) !important;
  background: var(--bg-Table-Disable) !important;
  min-height: 50px;
  padding: 7px 0 !important;
  justify-content: center;
  left: 0;
  position: sticky !important;
  top: auto;
  z-index: 42;
  min-width: 100px;
  width: 100px !important;
  line-height: 14px !important;
  align-items: center !important;

  .label {
    font-weight: var(--font-bolder);
    color: rgba(0, 0, 0, 0.6);
  }
}

.parameters-name {
  // font-weight: var(--font-bolder) !important;
  background: var(--bg-Table-Disable) !important;
  // min-height: 40px;
  height: 100%;
  // padding: 0px 0 !important;
  // justify-content: center;
  // left: 0;
  // position: sticky !important;
  // top: auto;
  // z-index: 42;
  // min-width: 100&;
  // width: 100% !important;
  // line-height: 14px !important;
  // align-items: center !important;

  // .label {
  //   font-weight: var(--font-bolder);
  //   color: rgba(0, 0, 0, 0.6);
  // }
  // .textarea {
  //   line-height: 10px !important;
  // }
}

.container-1 {
  height: calc(100vh - 151px) !important;
}

// Add Parameter Section Css

.parameter-add {
  padding: 0;
  margin-bottom: 15px;
  align-items: center;

  .MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
    font-size: 14px;
  }

  .MuiPaper-root {
    box-shadow: none !important;
  }
}

.btn-add-para {
  background-color: rgba(0, 0, 0, 0.7) !important;
  padding: 10px;
  // &.Mui-disabled {
  //   background-color: grey !important;
  //   color: var(--colorTextWhite);
  // }
}

// Add Parameter Section Css
// Row Reject css
.header-div {
  min-height: 50px;
}

.sub-row {
  min-height: 36px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .validatior {
    padding: 5px;
    border-bottom: none;
  }
}

.checkbox-wrap {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 45px;

  input[type="checkbox"] {
    accent-color: rgb(255, 134, 134);
  }

  .MuiCheckbox-root {
    padding: 5px 9px !important;

    &:hover {
      background-color: transparent !important;
    }
  }
}

// Validator scss
.btn-validate {
  @include button-bg-blue;
  font-size: 12px !important;
  padding: 4px !important;
  margin: 4px !important;
  min-width: 82px !important;

  &.Mui-disabled {
    color: var(--colorTextWhite);
    background-color: var(--bg-gray) !important;
  }
}

.validator-edit {
  position: relative;
  display: flex;

  svg {
    // margin-top: 7px;
    cursor: pointer;
  }

  .validator-lable-wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 24px;
    top: 0;
  }

  .label {
    // position:absolute!important;
    top: 1px !important;
    left: 30px !important;
    font-size: 12px !important;
    line-height: 16px !important;
    min-width: 130px;

    .MuiSvgIcon-root {
      font-size: 20px !important;
      margin: 4px;
    }
  }
}

.MuiSvgIcon-root {
  &.viewfull-screen {
    width: 26px !important;
    color: #202020;
    height: 26px !important;
  }
}

.add_new_parameter {
  margin: 1px 0 0px auto !important;
  height: 36px;
  padding: 7px 16px !important;
  @include button-black;
}

.add-new-para-modal {
  .MuiSvgIcon-root {
    width: 25px !important;
    height: 25px !important;
  }

  .new-para-container {
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .new-para-wrap {
      display: flex;
      align-items: baseline;

      .new-para-label {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.error-alert.MuiAlert-root {
  background-color: rgb(253, 237, 237) !important;
  color: rgb(95, 33, 32) !important;

  .MuiAlertTitle-root {
    color: rgb(95, 33, 32) !important;
    font-weight: var(--font-bolder);
  }

  .MuiAlert-icon {
    color: rgb(211, 47, 47) !important;
  }
}

.val-btn {
  padding: 0 16px;
}

.checkbox-wrap {
  .MuiCheckbox-root.Mui-checked {
    .MuiSvgIcon-root {
      color: rgb(255, 137, 116) !important;
    }
  }
}

.container-1,
.container-2 {
  .table-grid {
    height: 100%;
  }
}

.plusIcon {
  margin: 2px;
}

.add-para-note {
  margin-top: 10px;
}
